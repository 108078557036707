function Socials() {
  return (
    <div className="shadow rounded-lg flex flex-wrap items-center justify-center text-center gap-4 my-6 md:my-8">
      <a
        href="https://drive.google.com/file/d/1YMN1GiC9T2k41GvkWZuxKNpmYbaluuVo/view?usp=sharing"
        target="__blank"
        className="px-4 py-2 w-[150px] font-semibold"
      >
        Resume
      </a>
      <a
        href="mailto:anmolpattnayak2016@gmail.com"
        target="__blank"
        className="px-4 py-2 w-[150px] font-semibold"
      >
        Email
      </a>
      <a
        href="https://github.com/SirSimon162"
        target="__blank"
        className="px-4 py-2 w-[150px] font-semibold"
      >
        GitHub
      </a>
      <a
        href="twitter.com/_SirSimon_/"
        target="__blank"
        className="px-4 py-2 w-[150px] font-semibold"
      >
        Twitter
      </a>
      <a
        href="https://www.linkedin.com/in/anmol-pattnayak-8134b0197/"
        target="__blank"
        className="px-4 py-2 w-[150px] font-semibold"
      >
        Linkedin
      </a>
    </div>
  );
}

export default Socials;
