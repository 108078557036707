import React from 'react'

function Footer() {
  return (
    <div className='flex flex-col items-center my-2'>
        <div className='font-semibold'>Made with 💗 by Anmol Pattnayak</div>
        <div className='font-light'>(I Made This, No Cap)</div>
    </div>
  )
}

export default Footer