import Hero from "./components/Hero";
import Socials from "./components/Socials";
import About from "./components/About";
import TechStack from "./components/TechStack";
import Projects from "./components/Projects";
import Extracurricular from "./components/Extracurricular";
import Designs from "./components/Designs";

function HomePage() {
  return (
    <>
      <Hero />
      <Socials />
      <About />
      <TechStack />
      <Projects />
      <Extracurricular />
      <Designs />
    </>
  );
}

export default HomePage;
